import { SetStateAction, Dispatch, useState, useEffect } from "react";
import { QueuedBatch } from "../models/QueuedBatch";
import { ConvertPriorityValue } from "../helpers/priority-helper";

export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const INSERT_QUEUED_BATCHES = 'INSERT_QUEUED_BATCHES';
export const ORDER_BATCHES_BY_NAME = 'ORDER_BATCHES_BY_NAME';
export const ORDER_BATCHES_BY_FRAME = 'ORDER_BATCHES_BY_FRAME';
export const ORDER_BATCHES_BY_JOBS_COUNT = 'ORDER_BATCHES_BY_JOBS_COUNT';
export const ORDER_BATCHES_BY_DATE = 'ORDER_BATCHES_BY_DATE';
export const ORDER_BATCHES_BY_PRIORITY = 'ORDER_BATCHES_BY_PRIORITY';
export const REMOVE_BATCHES_FROM_QUEUE = 'REMOVE_BATCHES_FROM_QUEUE';
export const UPDATE_BATCH_RENDER_SETTINGS = 'UPDATE_BATCH_RENDER_SETTINGS';
export const UPDATE_GROUP_RENDER_SETTINGS = 'UPDATE_GROUP_RENDER_SETTINGS';

interface GlobalState {
    auth: { authorized: boolean, username: string, password: string },
    queuedBatches: QueuedBatch[],
    orderedBy: string | null
}

let listeners: Dispatch<SetStateAction<any>>[] = [];
let state: GlobalState =
{
    auth: { authorized: false, username: '', password: '' },
    queuedBatches: [],
    orderedBy: null
};

const listen = (state: GlobalState) => listeners.forEach(l => l(state));


export const useGlobalState = (): [GlobalState, (action: { type: string, payload?: any }) => void] => {
    const setState = useState(state)[1];

    const dispatch = (action: { type: string, payload?: any }) => {
        switch (action.type) {

            case SIGN_IN:
                state = { ...state, auth: { authorized: true, username: action.payload.username, password: action.payload.password } };
                break;

            case SIGN_OUT:
                state = { ...state, auth: { authorized: false, username: '', password: '' } };
                break;

            case INSERT_QUEUED_BATCHES:
                state = {
                    ...state,
                    queuedBatches: [
                        ...action.payload
                            .sort((a: QueuedBatch, b: QueuedBatch) => (b.priority - a.priority)
                                || (a.dateCreated.toString().localeCompare(b.dateCreated.toString())))
                            .map((b: QueuedBatch) => {
                                return {
                                    ...b,
                                    dateCreated: new Date(b.dateCreated),
                                    priority: ConvertPriorityValue(b.priority),
                                    isCloudRendering: b.isCloudRendering === null ? false : b.isCloudRendering
                                }
                            })],
                    orderedBy: ORDER_BATCHES_BY_PRIORITY
                };
                break;

            case ORDER_BATCHES_BY_NAME:
                state = {
                    ...state,
                    queuedBatches:
                        (state.orderedBy === ORDER_BATCHES_BY_NAME)
                            ? [...state.queuedBatches.reverse()]
                            : [...state.queuedBatches.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))],
                    orderedBy:
                        state.orderedBy === ORDER_BATCHES_BY_NAME
                            ? null
                            : ORDER_BATCHES_BY_NAME
                };
                break;

            case ORDER_BATCHES_BY_FRAME:
                state = {
                    ...state,
                    queuedBatches:
                        (state.orderedBy === ORDER_BATCHES_BY_FRAME)
                            ? [...state.queuedBatches.reverse()]
                            : [...state.queuedBatches.sort((a, b) => a.frameCount - b.frameCount)],
                    orderedBy:
                        (state.orderedBy === ORDER_BATCHES_BY_FRAME)
                            ? null
                            : ORDER_BATCHES_BY_FRAME
                };
                break;

            case ORDER_BATCHES_BY_JOBS_COUNT:
                state = {
                    ...state,
                    queuedBatches:
                        (state.orderedBy === ORDER_BATCHES_BY_JOBS_COUNT)
                            ? [...state.queuedBatches.reverse()]
                            : [...state.queuedBatches.sort((a, b) => {
                                if (
                                    a.renderJobsCount &&
                                    a.postJobsCount &&
                                    a.contentJobsCount &&
                                    b.renderJobsCount &&
                                    b.postJobsCount &&
                                    b.contentJobsCount) {

                                    return (
                                        [a.renderJobsCount, a.postJobsCount, a.contentJobsCount].reduce((f, s) => f + s)
                                        - [b.renderJobsCount, b.postJobsCount, b.contentJobsCount].reduce((f, s) => f + s))
                                }

                                return 0;
                            })],
                    orderedBy:
                        (state.orderedBy === ORDER_BATCHES_BY_JOBS_COUNT)
                            ? null
                            : ORDER_BATCHES_BY_JOBS_COUNT
                };
                break;

            case ORDER_BATCHES_BY_PRIORITY:
                state = {
                    ...state,
                    queuedBatches:
                        state.orderedBy === ORDER_BATCHES_BY_PRIORITY
                            ? [...state.queuedBatches.reverse()]
                            : [...state.queuedBatches.sort((a, b) => a.priority - b.priority)],
                    orderedBy:
                        state.orderedBy === ORDER_BATCHES_BY_PRIORITY
                            ? null
                            : ORDER_BATCHES_BY_PRIORITY
                };
                break;

            case ORDER_BATCHES_BY_DATE:
                state = {
                    ...state,
                    queuedBatches:
                        state.orderedBy === ORDER_BATCHES_BY_DATE
                            ? [...state.queuedBatches.reverse()]
                            : [...state.queuedBatches.sort((a, b) =>
                                (a.dateCreated.getTime() > b.dateCreated.getTime())
                                    ? 1
                                    : (b.dateCreated.getTime() > a.dateCreated.getTime())
                                        ? -1
                                        : 0)],
                    orderedBy:
                        state.orderedBy === ORDER_BATCHES_BY_DATE
                            ? null
                            : ORDER_BATCHES_BY_DATE
                };
                break;

            case REMOVE_BATCHES_FROM_QUEUE:
                state = {
                    ...state,
                    queuedBatches: state.queuedBatches.filter(b => !action.payload.includes(b.batchId))
                };
                break;

            case UPDATE_BATCH_RENDER_SETTINGS:
                state = {
                    ...state,
                    queuedBatches: state.queuedBatches.map(b => {
                        if (b.batchId === action.payload.batchId) {
                            return {
                                ...b,
                                isCloudRendering: action.payload.cloudRendering,
                                priority: action.payload.priority
                            };
                        }

                        return { ...b };
                    })
                };
                break;

            case UPDATE_GROUP_RENDER_SETTINGS:
                state = {
                    ...state,
                    queuedBatches: state.queuedBatches.map(b => {
                        var findBatch = action.payload.batches.find((batch: QueuedBatch) => batch.batchId === b.batchId);
                        if (findBatch) {
                            return {
                                ...b,
                                isCloudRendering: action.payload.cloudRendering,
                                priority: action.payload.priority
                            }
                        }

                        return { ...b };
                    })
                }
                break;

            default:
                throw new Error('Wrong dispatch action type provided.');
        }

        listen(state);
    };

    useEffect(() => {
        listeners.push(setState);
    }, [setState])

    return [state, dispatch];
};
