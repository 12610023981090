import React, { useState, useReducer, Component, useEffect } from 'react';

import './Login.css';
import useHttp from '../../custom-hooks/http/http';
import { useGlobalState, SIGN_IN, INSERT_QUEUED_BATCHES } from '../../state/global-state';
import * as ENDPOINTS from '../../endpoints/http-endpoints';
import Auxilary from '../Auxiliary/Auxiliary';
import ResponseModal from '../Modals/ResponseModal/ResponseModal';
import modalReducer, { modalReducerInitialState } from '../../reducers/modal-reducer';
import { HIDE_RESPONSE_MODAL, SHOW_RESPONSE_MODAL } from '../../reducers/modal-reducer-actions';
import Spinner from '../UI/Spinner/Spinner';
import { AxiosError } from 'axios';
import { set, get } from 'local-storage';
import { User } from '../../models/User';

const Login = () => {
    const [__, stateDispatcher] = useGlobalState();
    const { request, isLoading } = useHttp();
    const [{ responseModal }, modalDispatacher] = useReducer(modalReducer, modalReducerInitialState);
    const [logging, setLogging] = useState(false);
    const [user, setUser] = useState<User>({ username: '', password: '' });

    const checkEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            login(user);
        }
    };

    /* on component mount */
    useEffect(() => {
        var storageUser = get<string>('user');
        if (storageUser) {
            var user: User = JSON.parse(storageUser);
            login(user);
        }
    }, [])

    /* on loggin */
    const login = (user: User) => {
        if (logging === false && responseModal.show === false) {
            setLogging(true);
            request(ENDPOINTS.RETRIEVE_QUEUED_BATCHES, 'GET', { username: user.username, password: user.password })
                .then(response => {
                    if (response) {
                        set('user', JSON.stringify(user));
                        stateDispatcher({ type: INSERT_QUEUED_BATCHES, payload: response });
                        stateDispatcher({ type: SIGN_IN, payload: { username: user.username, password: user.password } });
                    }
                })
                .catch((error: AxiosError) => {
                    if (error.response?.status === 401) {
                        modalDispatacher({ type: SHOW_RESPONSE_MODAL, responseMessage: <h4> Wrong username or password provided </h4>, responseType: 'error' })
                    }
                    else {
                        modalDispatacher({ type: SHOW_RESPONSE_MODAL, responseMessage: <h4> Something went wrong </h4>, responseType: 'error' });
                    }
                });
        }
        setLogging(false);
    };

    return (
        <Auxilary>
            <div className='html'>
                <div className='body'>
                    <div className="container" >
                        <div className="d-flex justify-content-center h-100">
                            <div className="card cardLogin" style={{ marginTop: '20vh' }}>
                                <div className="card-body" style={{ textAlign: 'center' }}>
                                    <h3 style={{ color: 'white' }}>Sign In</h3>
                                    <svg width="90" height="90" viewBox="0 0 46 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M34.8 6.60054L34.797 1.21561C34.797 0.961452 34.7607 0.828327 34.6521 0.699986C32.967 0.699986 20.2587 7.33046 20.2002 19.9562C20.2587 32.6695 32.967 39.3 34.6521 39.3C34.7607 39.1716 34.797 39.0385 34.797 38.7844L34.8 33.4012C31.5852 33.4012 24.7714 28.2143 24.7352 20C24.7714 11.7859 31.5852 6.60054 34.8 6.60054Z" fill="url(#paint0_linear)"></path><path fillRule="evenodd" clipRule="evenodd" d="M30.1275 34.16C22.2573 34.16 15.8772 27.8204 15.8772 20C15.8772 12.1796 22.2573 5.84 30.1275 5.84C31.7564 5.84 33.3413 6.10017 34.7994 6.60036L34.797 1.05747C34.797 0.769871 34.5995 0.49894 34.3272 0.436016C32.9726 0.150314 31.5677 0 30.1275 0C19.0114 0 10 8.95431 10 20C10 31.0457 19.0114 40 30.1275 40C31.5689 40 32.975 39.8494 34.3306 39.5633C34.6091 39.4988 34.797 39.2373 34.797 38.9429L34.7994 33.4009C33.3396 33.9025 31.7588 34.16 30.1275 34.16Z" fill="#E2FFFC"></path><defs><linearGradient id="paint0_linear" x1="20.2" y1="-13.6048" x2="32.6" y2="-13.6048" gradientUnits="userSpaceOnUse"><stop stopColor="white" stopOpacity="0.28"></stop><stop offset="1" stopColor="white"></stop></linearGradient></defs></svg>
                                </div>
                                <div className="card-body">
                                    <div>
                                        <div className="input-group form-group">
                                            <input type="text" className="form-control" placeholder="username"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, username: e.currentTarget.value })}
                                                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => checkEnter(event)} />
                                        </div>
                                        <div className="input-group form-group">
                                            <input type="password" className="form-control" placeholder="password"
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUser({ ...user, password: e.currentTarget.value })}
                                                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => checkEnter(event)} />
                                        </div>
                                        <div className="form-group">
                                            <button
                                                className="btn float-right login_btn"
                                                onClick={() => login(user)} >Login</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ResponseModal
                show={responseModal.show}
                close={() => modalDispatacher({ type: HIDE_RESPONSE_MODAL })}
                type={responseModal.responseType}>{responseModal.message}</ResponseModal>
            <Spinner show={isLoading} />
        </Auxilary>
    );
};

export default Login;