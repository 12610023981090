import React from 'react';

import Header from './CardHeader/CardHeader';
import Navbar from './CardNavbar/CardNavbar';
import Footer from './CardFooter/CardFooter';

const Card = (props: {
    title: string;
    actions: any;
    children: any;
    style?: React.CSSProperties;
    filters: { byName: () => void, byDate: () => void, byPriority: () => void, byFrame: () => void, byJobsCount: () => void };
    search: (value: string) => void,
    refresh: () => void,
    isFilterLocalRender: boolean,
    setFilterRenderEnvironment: (value: boolean) => void,
    queueCount: {
        batchCount: number,
        renderingCount: number,
        denoiseCount: number,
        upscaleCount: number,
        postCount: number,
        contentCount: number
    },
    filterBatchesByPriority: (priority: number) => void,
    currentFilterPriority: number,
    onBulkGroupEdit: () => void;
    showBulkEditModal: boolean;
    selectAllGroups: (selected: boolean) => void;
    allSelected: boolean;
    onBulkRemove: () => void;
}) => {
    return (
        <div className="card shadow mb-4" style={{ top: '3vh' }}>
            <Header
                title={props.title}
                search={props.search}
                refresh={props.refresh}
                isLocalRender={props.isFilterLocalRender}
                setRenderEnvironment={props.setFilterRenderEnvironment}
                filterBatchesByPriority={props.filterBatchesByPriority}
                currentFilterPriority={props.currentFilterPriority}
                onBulkGroupEdit={props.onBulkGroupEdit}
                showBulkEditModal={props.showBulkEditModal}
                selectAllGroups={props.selectAllGroups}
                allSelected={props.allSelected}
                onBulkRemove={props.onBulkRemove}>
                {props.actions}
            </Header>
            <div className='Overflow'
                style={{
                    maxHeight: '82vh',
                    overflowY: props.children.length > 10 ? 'auto' : 'unset',
                    overflowX: props.children.length > 10 ? 'hidden' : 'unset'
                }}>
                <Navbar filters={props.filters} />
                {props.children}
            </div>
            <Footer {...props.queueCount} />
        </div>);
}

export default Card;