import React, { useState, useEffect, useCallback } from 'react';
import { Checkbox } from 'pretty-checkbox-react';

import { QueuedBatch } from '../../../models/QueuedBatch';
import Modal from '../../UI/Modal/Modal';
import { RetrievePriorityEnums, EnumKeyValue } from '../../../helpers/priority-helper';
import backdropClasses from '../../UI/Backdrop/Backdrop.module.css';
import modalClasses from '../../UI/Modal/Modal.module.css';

const RenderSettingsModal = (props: {
    batch: QueuedBatch | undefined,
    show: boolean,
    onClose: () => void,
    onSave: (batch: QueuedBatch, priority: number, cloudRendering: boolean, forTaskRunner: boolean, forChaosCloud: boolean) => boolean
}) => {
    const [checked, setChecked] = useState(false);
    const [priority, setPriority] = useState<number | undefined>();
    const [enums, setEnums] = useState<EnumKeyValue[]>();
    const [forTaskRunner, setForTaskRunner] = useState(false);
    const [forChaosCloud, setForChaosCloud] = useState(false);

    /* on batch changed fill data */
    useEffect(() => {
        if (props.batch) {
            setChecked(props.batch.isCloudRendering ? true : false);
            setPriority(props.batch.priority ? props.batch.priority : undefined);
            setForTaskRunner(props.batch.forTaskRunner ? true : false);
            setForChaosCloud(props.batch.forChaosCloud ? true : false);
        }
    }, [props.batch])

    /* on component mount */
    useEffect(() => {
        setEnums(RetrievePriorityEnums());
        return () => { setChecked(false); };

    }, [])


    /* create option for provided enum value */
    const createOption = useCallback((option: EnumKeyValue): JSX.Element | void => {
        return (
            <option
                key={option.key}
                value={option.key}>
                {`${option.key} (${option.value})`}  {/* 2 (low) */}
            </option >
        );
    }, []);

    return (
        <Modal
            show={props.show}
            closeForm={props.onClose}
            backdropClasses={backdropClasses.LowIndexBackdrop}
            classes={modalClasses.LowIndexModal}>
            <div style={{ textAlign: 'center' }}>
                <h5 className='text-info'>{props.batch && props.batch.name}</h5>
                <hr />
                <h3 className='text-info'> Rendering settings </h3>
                <br />
                <br />
                <div className="row">
                    <div className="offset-md-3 col-md-3">
                        <div className="row">
                            <div className="col-md-3">
                                <label>Priority</label>
                            </div>
                            <div className="col-md-8" style={{ bottom: '7px' }}>
                                <select
                                    className='custom-select'
                                    value={priority}
                                    onChange={(e) => setPriority(+e.currentTarget.value)}>
                                    {
                                        enums && enums.map((option: EnumKeyValue) => createOption(option))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-4">
                                <label>Cloud rendering:</label>
                            </div>
                            <div className="col-md-1">
                                <Checkbox
                                    state={checked}
                                    checked={checked}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChecked(e.currentTarget.checked)}
                                    shape='round'
                                    color='success'
                                    animation='smooth' />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-4">
                                <label>For TaskRunner:</label>
                            </div>
                            <div className="col-md-1">
                                <Checkbox
                                    state={forTaskRunner}
                                    checked={forTaskRunner}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setForTaskRunner(e.currentTarget.checked)}
                                    shape='round'
                                    color='success'
                                    animation='smooth' />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-4">
                                <label>For Chaos Cloud:</label>
                            </div>
                            <div className="col-md-1">
                                <Checkbox
                                    state={forChaosCloud}
                                    checked={forChaosCloud}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setForChaosCloud(e.currentTarget.checked)}
                                    shape='round'
                                    color='success'
                                    animation='smooth' />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <hr />
                <button
                    className='btn'
                    style={{ backgroundColor: 'rgb(20, 176, 156)', color: 'white' }}
                    onClick={() => {
                        if (props.batch && priority) {
                            if (props.onSave(props.batch, priority, checked, forTaskRunner, forChaosCloud))
                                props.onClose();
                        }
                    }}> Update </button>
                <button
                    className='btn btn-danger'
                    onClick={props.onClose}> Cancel </button>
            </div>
        </Modal>
    );
};

export default RenderSettingsModal;