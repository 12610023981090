import React, { useState, useEffect } from 'react';
import { EnumKeyValue, RetrievePriorityEnums, CreateOption } from '../../helpers/priority-helper';

const PriorityFilter = (props: {
    filterByPriority: (priority: number) => void,
    currentPriority: number
}) => {
    const [enums, setEnums] = useState<EnumKeyValue[]>();

    /* on component mount */
    useEffect(() => {
        setEnums(RetrievePriorityEnums());
    }, [])

    return (
        <div className="row" style={{ textAlign: 'center' }}>
            <div className="col-md-2" style={{marginTop: '0.5vh'}}>
                <label>Priority</label>
            </div>
            <div className="offset-md-2 col-md-7" style={{height: '1.3vh', marginBottom: '2vh'}}>
                <select
                    className='custom-select'
                    value={props.currentPriority}
                    onChange={(e) => props.filterByPriority(+e.currentTarget.value)}>
                    <option value={-1}>None</option>
                    {
                        enums && enums.map((option: EnumKeyValue) => CreateOption(option))
                    }
                </select>
            </div>
        </div>
    );
};

export default PriorityFilter;