import axios, { Method, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

import { useReducer, useCallback, useEffect } from "react";
import { Promise } from "q";
import * as Sentry from '@sentry/browser';

const SEND = 'SEND';
const RESPONSE = 'RESPONSE';
const ERROR = 'ERROR';
const CLEAR = 'CLEAR';

type HttpState = {
    request: (url: string, method: Method, auth: { username: string, password: string }, body?: any, timeoutMiliseconds?: number) => Promise<any>;
    isLoading: boolean;
    clearError: () => void;
    error?: string;
};

type HttpAction = {
    type: string;
    errorMessage?: string;
};

const httpReducer = (curHttpState: HttpState, action: HttpAction): HttpState => {
    switch (action.type) {
        case SEND:
            return { ...curHttpState, isLoading: true };
        case RESPONSE:
            return { ...curHttpState, isLoading: false };
        case ERROR:
            return { ...curHttpState, isLoading: false, error: action.errorMessage };
        case CLEAR:
            return { ...curHttpState, isLoading: false, error: undefined };

        default:
            throw new Error('Wrong action provided in http reducer!');
    }
};

const useHttp = (): HttpState => {
    const clearError = useCallback((): void => dispatchHttp({ type: CLEAR }), []);
    const request = (url: string, method: Method, auth: { username: string, password: string }, body?: any, timeoutMiliseconds: number = 120000): Promise<any> => {
        return Promise((resolve, reject) => {
            console.log(url);
            dispatchHttp({ type: SEND });

            let axiosReqConf: AxiosRequestConfig = {
                url: url,
                method: method,
                data: body,
                timeout: timeoutMiliseconds,
                headers: { username: auth.username, password: auth.password }
            }
            console.log(axiosReqConf);
            
            axios(axiosReqConf)
                .then((response: AxiosResponse) => {
                    dispatchHttp({ type: RESPONSE });
                    resolve(response.data);
                })
                .catch((error: AxiosError) => {
                    Sentry.withScope(scope => {
                        scope.setExtras({
                            response: error.response
                        });
                        Sentry.captureException(error);
                    });

                    dispatchHttp({ type: ERROR, errorMessage: error.response ? error.response.data : error.message });
                    reject(error);
                });
        })
    };

    const [httpState, dispatchHttp] = useReducer(httpReducer, {
        isLoading: false,
        request: request,
        clearError: clearError
    });

    return {
        request: httpState.request,
        isLoading: httpState.isLoading,
        error: httpState.error,
        clearError: httpState.clearError
    };
}

export default useHttp;