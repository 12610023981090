import React from 'react';

const EnvironmentFilter = (props: {
    isLocalRender: boolean,
    filterByRenderEnvironment: (isLocalEnvironment: boolean) => void
}) => {

    return (
        <div className="row">
            <div className="col-md-4">
                <label>Environment</label>
            </div>
            <div className="col-md-2">
                <div className="pretty p-switch">
                    <input
                        checked={props.isLocalRender ? false : true}
                        type="checkbox"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            props.filterByRenderEnvironment(!e.currentTarget.checked);
                        }} />
                    <div className="state">
                        <label className='text-dark'>{props.isLocalRender ? 'Local' : 'Cloud'}</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnvironmentFilter;