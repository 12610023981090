import React, { useState } from 'react';
import PriorityFilter from '../PriorityFilter/PriorityFilter';
import EnvironmentFilter from '../EnvironmentFilter/EnvironmentFilter';
import Backdrop from '../UI/Backdrop/Backdrop';
import backdropClasses from '../UI/Backdrop/Backdrop.module.css';

const FilterOptions = (props: {
    filterByPriority: (priority: number) => void,
    filterByRenderEnvironment: (localEnvironemnt: boolean) => void
    isLocalRender: boolean,
    currentFilterPriority: number
}) => {

    const [showFilterModal, setShowFilterModal] = useState(false);

    return (
        <div className="row">
            <div
                className="dropdown no-arrow col-md-12"
                data-tip={'Filter queued batches'}>
                <a className="dropdown-toggle" href={(props.isLocalRender === false || props.currentFilterPriority !== -1) ? '#' : undefined} role="button" onClick={() => setShowFilterModal(!showFilterModal)} >
                    <i className={'fa fa-sliders'}></i>
                </a>
                {
                    showFilterModal
                        ?
                        <div
                            className="dropdown-menu dropdown-menu-right shadow animated-fade-in show"
                            aria-labelledby="dropdownMenuLink"
                            style={{ width: '23vh', textAlign: 'left', padding: '1vh', paddingLeft: '2vh' }}>
                            <h5 className="dropdown-header text-info" style={{ textAlign: 'center' }}> filters </h5>
                            <hr />
                            <PriorityFilter
                                filterByPriority={props.filterByPriority}
                                currentPriority={props.currentFilterPriority} />
                            <hr />
                            <EnvironmentFilter
                                filterByRenderEnvironment={props.filterByRenderEnvironment}
                                isLocalRender={props.isLocalRender} />
                        </div>
                        : null}
            </div>
            <Backdrop
                classes={backdropClasses.TransparentBackdrop}
                show={showFilterModal}
                clicked={() => setShowFilterModal(!showFilterModal)}
            />
        </div >
    );
};

export default FilterOptions;