import React, { useEffect } from 'react';
import { BatchGroup } from '../../../helpers/priority-helper';
import { QueuedBatch } from '../../../models/QueuedBatch';

import Modal from '../../UI/Modal/Modal';

const ConfirmationModal = (props: {
    title: string;
    groups?: BatchGroup[];
    showModal: boolean;
    onConfirm: any;
    toggleModalHandler: () => void;
}) => {

    let form = (
        <Modal
            classes={undefined}
            show={props.showModal}
            closeForm={props.toggleModalHandler}>
            <div style={{ textAlign: 'center' }}>
                <h5 className='text-info'>{props.title}</h5>
                {props.groups ? <hr /> : null}
                <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
                    {
                        props.groups
                            ?
                            props.groups.map((el, elIndex) => {
                                return (
                                    <div key={elIndex}>
                                        <span className='text-info'>{el.groupName}</span>
                                        <ul style={{ listStyle: 'none' }}>
                                            {
                                                el.batches.map((elb, elbIndex) => <li key={elbIndex} style={{ color: 'black' }}>{elb.batchId}</li>)
                                            }
                                        </ul>
                                    </div>
                                )
                            })
                            : null
                    }
                </div>
                <hr />
                <button
                    className='btn'
                    style={{ backgroundColor: 'rgb(20, 176, 156)', color: 'white' }}
                    onClick={() => { props.onConfirm(); props.toggleModalHandler(); }}> Confirm </button>
                <button
                    className='btn btn-danger'
                    onClick={props.toggleModalHandler}> Cancel </button>
            </div>
        </Modal>);

    return form;
};

export default ConfirmationModal;