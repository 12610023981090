import { BatchGroup } from "./priority-helper";
import { JobType } from '../models/Job-type.enum';

export const sumJobs = (rendering?: number, denoise?: number, upscale?: number, post?: number, content?: number) => {
    var ren = rendering ?? 0;
    var den = denoise ?? 0;
    var ups = upscale ?? 0;
    var pos = post ?? 0;
    var con = content ?? 0;
    
    return (ren + den + ups + pos + con);
}

export const sumPropertyFromArray = (array: any[], key: string) => {
    return array.reduce((a, b) => a + (b[key] || 0), 0);
}

export const sumGroupJobsCount = (group: BatchGroup, type: JobType) => {
    var jobsCount = 0;

    switch (type) {
        case JobType.rendering:
            group.batches.forEach(b => jobsCount += b.renderJobsCount ?? 0);
            return jobsCount;
        case JobType.denoise:
            group.batches.forEach(b => jobsCount += b.denoiseJobsCount ?? 0);
            return jobsCount;
        case JobType.upscale:
            group.batches.forEach(b => jobsCount += b.upscaleJobsCount ?? 0);
            return jobsCount;
        case JobType.post:
            group.batches.forEach(b => jobsCount += b.postJobsCount ?? 0);
            return jobsCount;
        case JobType.content:
            group.batches.forEach(b => jobsCount += b.contentJobsCount ?? 0);
            return jobsCount;

        default:
            return jobsCount;
    }
}