import React from 'react';

const BulkRemove = (props: { show: boolean, onRemove: () => void }) => {

    return (
        <i
            data-tip='Bulk remove'
            style={{ cursor: 'pointer', display: props.show ? 'inline' : 'none' }}
            className='fas fa-trash text-danger'
            onClick={props.onRemove} />);
};

export default BulkRemove;