import React from 'react';

import classes from './Modal.module.css';
import Aux from '../../Auxiliary/Auxiliary';
import Backdrop from '../Backdrop/Backdrop';

export enum ModalSize {
    S = 25,
    M = 50,
    L = 75
}

const Modal = (props: {
    show: boolean;
    closeForm: () => void;
    children: any;
    classes?: any;
    transparent?: boolean;
    backdropClasses?: string;
    modalSize?: ModalSize
}) => {

    const modalClasses = props.classes ? props.classes : props.transparent ? classes.ModalTransparent : classes.Modal;

    return (
        <Aux>
            <div
                className={modalClasses}
                style={{
                    transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                    opacity: props.show ? 1 : 0,
                    width: props.modalSize ? `${props.modalSize}%` : `${ModalSize.M}%`,
                    left: props.modalSize
                        ? props.modalSize === ModalSize.S
                            ? '37.5%'
                            : '12.5%' 
                        : '25%'
                }}>
                {props.children}
            </div>
            <Backdrop show={props.show} clicked={props.closeForm} classes={props.backdropClasses} />
        </Aux>
    );
}

export default Modal;