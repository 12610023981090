import React from 'react';

import classes from '../../../styles/styles.module.css';
import './CardNavbar.css';

const CardNavbar = (props: { filters: { byName: () => void, byDate: () => void, byPriority: () => void, byFrame: () => void, byJobsCount: () => void } }) => {
    return (
        <div className="row CardNavbar">
            <div
                data-tip='Sort by name'
                className={['col-md-5', classes.FilterHover, classes.Clickable].join(' ')}
                onClick={props.filters.byName}>
                <span className='text-info'>Name</span>
            </div>
            <div
                data-tip='Sort by frame'
                className={['col-md-1', classes.FilterHover, classes.Clickable].join(' ')}
                onClick={props.filters.byFrame}>
                <span className='text-info'>Frame</span>
            </div>
            <div
                data-tip='Sort by jobs count'
                className={['col-md-2', classes.FilterHover, classes.Clickable].join(' ')}
                onClick={props.filters.byJobsCount}>
                <span className='text-info'>ren/den/ups/pos/con - total</span>
            </div>
            <div
                data-tip='Sort by date'
                className={['col-md-2', classes.FilterHover, classes.Clickable].join(' ')}
                onClick={props.filters.byDate}>
                <span className='text-info'>Date</span>
            </div>
            <div
                data-tip='Sort by priority'
                className={['col-md-1', classes.FilterHover, classes.Clickable].join(' ')}
                onClick={props.filters.byPriority}>
                <div className="row">
                    <div className="col-md-12">
                        <span className='text-info'>Priority</span>
                    </div>
                </div>
            </div>
                {/* <div
                    className={['col-md-1', classes.Clickable].join(' ')}>
                    <span className='text-info' >Version batch count</span>
                </div> */}
        </div>
    );
};

export default CardNavbar;