import React, { useState } from 'react';

const SelectAll = (props: { selectAllGroups: (selected: boolean) => void, areAllSelected: boolean }) => {

    return (
        <div
            style={{ height: '1em', width: '1em', cursor: 'pointer', position: 'relative', top: '0.7vh' }}
            onClick={() => { props.selectAllGroups(!props.areAllSelected) }}
            data-tip='Select all'>
            <i className="far fa-square" style={{ position: 'absolute', top: 0, left: 0 }} />
            <i className="fas fa-square fa-inverse" style={{ position: 'absolute', top: 2, left: 2 }} />
            <i className={props.areAllSelected ? "far fa-check-square" : 'far fa-square'} style={{ position: 'absolute', top: 3, left: 3 }} />
            <i className="far fa-square" style={{ position: 'absolute', top: 3, left: 3 }} />
        </div>
    );
};

export default SelectAll;