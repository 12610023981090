import React from 'react';
import Login from './components/Login/Login';
import BatchContainer from './containers/batch-container';
import Layout from './components/Layout/Layout';
import { useGlobalState } from './state/global-state';

const BatchQueueApp = () => {
    const { auth } = useGlobalState()[0];

    return auth.authorized ?
        (
            <Layout>
                <BatchContainer></BatchContainer>
            </Layout>
        ) :
        (
                <Login></Login>
        );
};

export default BatchQueueApp;