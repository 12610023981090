import React from 'react';

import classes from './Backdrop.module.css';

const backdrop = (props: {
    show: boolean,
    clicked: () => void,
    classes?: string
}) => (
        props.show ?
            <div
                className={props.classes ? props.classes : classes.Backdrop}
                onClick={props.clicked} /> : null
    );

export default backdrop;