import React from 'react';

import Modal, { ModalSize } from '../../UI/Modal/Modal';
import classes from '../../UI/Modal/Modal.module.css';

const ResponseModal = (props: { show: boolean, close: () => void, type: string, children: JSX.Element | JSX.Element[] }) => {
    return (
        <Modal
            modalSize={ModalSize.S}
            show={props.show}
            closeForm={props.close}
            classes={props.type === 'error'
                ? classes.ErrorModal
                : props.type === 'success'
                    ? classes.SuccessModal
                    : classes.InfoModal}> {props.children}</Modal >
    );
};

export default ResponseModal;