import React, { useState } from 'react';

import Refresh from '../../Refresh/Refresh';
import Search from '../../Search/Search';
import PriorityFilter from '../../PriorityFilter/PriorityFilter';
import FilterOptions from '../../FilterOptions/FilterOptions';
import BulkEdit from '../../BulkEdit/BulkEdit';
import SelectAll from '../../SelectAll/SelectAll';
import BulkRemove from '../../BulkRemove/BulkRemove';

interface CardHeaderProps {
    title: string;
    children: JSX.Element;
    search: (value: string) => void;
    refresh: () => void;
    isLocalRender: boolean;
    setRenderEnvironment: (checked: boolean) => void;
    filterBatchesByPriority: (priority: number) => void;
    currentFilterPriority: number;
    onBulkGroupEdit: () => void;
    showBulkEditModal: boolean;
    selectAllGroups: (selected: boolean) => void;
    allSelected: boolean;
    onBulkRemove: () => void;
};

const CardHeader = (props: CardHeaderProps) => {

    return (
        <div className="card-header py-1.5 flex-row" style={{ textAlign: 'center', alignContent: 'center', alignItems: 'center' }}>
            <div className="row">
                <div className="col-md-3">
                    <div className="row">
                        <div className="col-md-1">
                            <SelectAll selectAllGroups={props.selectAllGroups} areAllSelected={props.allSelected} />
                        </div>
                        <div className="col-md-11" style={{ marginLeft: '-0.5vh' }}>
                            <Search search={props.search}></Search>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <h6 className="m-0 font-weight-bold text-dark" style={{ textAlign: 'center' }} > {props.title}</h6>
                </div>
                <div className="col-md-3">
                    <div className="row">
                        <div className="offset-md-7 col-md-1" style={{ paddingTop: '0.45vh', paddingLeft: '2.45vh' }}>
                            <FilterOptions
                                filterByPriority={props.filterBatchesByPriority}
                                filterByRenderEnvironment={props.setRenderEnvironment}
                                isLocalRender={props.isLocalRender}
                                currentFilterPriority={props.currentFilterPriority} />
                        </div>
                        <div className="col-md-1">
                            <Refresh click={props.refresh} />
                        </div>
                        <div className="col-md-1">
                            <BulkEdit onClick={props.onBulkGroupEdit} showBulkEditModal={props.showBulkEditModal} />
                        </div>
                        <div className="col-md-1">
                            <BulkRemove show={props.showBulkEditModal} onRemove={props.onBulkRemove} />
                        </div>
                    </div>
                </div>
            </div>
            {props.children}
        </div >
    );
}

export default CardHeader;