import React from "react";
import ReactTooltip from "react-tooltip";

import { QueuedBatch } from "../../models/QueuedBatch";
import classes from "../../styles/styles.module.css";
import { sumJobs } from "../../helpers/sum-jobs-helper";
import Auxilary from "../Auxiliary/Auxiliary";
import "./QueuedBatchCss.css";
import Highlighter from "react-highlight-words";

const QueuedBatchComponent = (props: {
  batch: QueuedBatch;
  removeBatch: () => void;
  keyNumber: number;
  actions: JSX.Element;
  searchValue: string;
}) => {
  return (
    <Auxilary>
      <div className={["card-body", classes.CardBody].join(" ")}>
        <div className="row" style={{ textAlign: "center" }}>
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-1">{props.keyNumber}</div>
              <Highlighter
                className="col-md-11 Highlighter"
                unhighlightStyle={{ textAlign: "left" }}
                textToHighlight={props.batch.name.substring(
                  props.batch.name.indexOf("Batch")
                )}
                searchWords={[props.searchValue]}
                autoEscape={true}
              ></Highlighter>
            </div>
          </div>
          <div className="col-md-1" style={{ paddingLeft: "0.7vh" }}>
            &nbsp;&nbsp;&nbsp;{props.batch.frameCount}
          </div>
          <div className="col-md-2">
            {`${props.batch.renderJobsCount}/${
              props.batch.denoiseJobsCount ?? 0
            }/${props.batch.upscaleJobsCount ?? 0}/${
              props.batch.postJobsCount
            }/${props.batch.contentJobsCount} - ${sumJobs(
              props.batch.renderJobsCount,
              props.batch.denoiseJobsCount,
              props.batch.upscaleJobsCount,
              props.batch.postJobsCount,
              props.batch.contentJobsCount
            )}`}
          </div>
          <div className="col-md-2">
            &nbsp;&nbsp;&nbsp;{props.batch.dateCreated.toUTCString()}
          </div>
          <div className="col-md-1">{props.batch.priority}</div>
          <div className="col-md-1">
            <div className="row">
              {/* <div className="col-md-6">
                                {props.actions}
                            </div> */}
              <div className="col-md-12">
                <i
                  style={{ cursor: "pointer" }}
                  data-tip="Remove"
                  className="fas fa-trash text-danger"
                  onClick={props.removeBatch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Auxilary>
  );
};

export default QueuedBatchComponent;
