import React, { useState } from 'react';

const BulkEdit = (props: {
    onClick: () => void;
    showBulkEditModal: boolean;
}) => {

    return (
        <i
            onClick={props.onClick}
            data-tip='Bulk edit'
            className="fas fa-align-right"
            style={{
                pointerEvents: props.showBulkEditModal ? "auto" : "none",
                color: props.showBulkEditModal ? 'blue' : 'transparent',
                cursor: props.showBulkEditModal ? "pointer" : "not-allowed"
            }} />
    );
};

export default BulkEdit;