import {
    SHOW_CONFIRMATION_MODAL,
    HIDE_CONFIRMATION_MODAL,
    SHOW_RESPONSE_MODAL,
    HIDE_RESPONSE_MODAL,
    SHOW_BATCH_RENDER_SETTINGS_MODAL,
    HIDE_BATCH_RENDER_SETTINGS_MODAL,
    SHOW_GROUP_RENDER_SETTINGS_MODAL,
    HIDE_GROUP_RENDER_SETTINGS_MODAL
} from './modal-reducer-actions';
import { QueuedBatch } from '../models/QueuedBatch';
import { BatchGroup } from '../helpers/priority-helper';

const modalReducer = (
    state: {
        confirmationModal: { show: boolean, confirmAction: (param?: any) => any, confirmMessage: string, groups?: BatchGroup[] },
        responseModal: { show: boolean, responseType: string, message: any },
        renderSettingsModal: { show: boolean, batch?: QueuedBatch }
        groupRenderSettingsModal: { show: boolean, group?: BatchGroup }
    },
    action: {
        type: string,
        confirmAction?: (param?: any) => any,
        confirmMessage?: string,
        responseMessage?: JSX.Element,
        responseType?: string,
        batch?: QueuedBatch,
        group?: BatchGroup,
        groups?: BatchGroup[]
    })
    : {
        confirmationModal: { show: boolean, confirmAction: (param?: any) => void, confirmMessage: string, groups?: BatchGroup[] },
        responseModal: { show: boolean, responseType: string, message: any },
        renderSettingsModal: { show: boolean, batch?: QueuedBatch },
        groupRenderSettingsModal: { show: boolean, group?: BatchGroup }
    } => {

    switch (action.type) {
        case SHOW_CONFIRMATION_MODAL:
            return {
                ...state,
                confirmationModal: {
                    show: true,
                    confirmAction: action.confirmAction ? action.confirmAction : () => null,
                    confirmMessage: action.confirmMessage ? action.confirmMessage : '',
                    groups: action.groups ? action.groups : undefined
                }
            };
        case HIDE_CONFIRMATION_MODAL:
            return { ...state, confirmationModal: { show: false, confirmAction: () => null, confirmMessage: '', groups: undefined } };
        case SHOW_RESPONSE_MODAL:
            return { ...state, responseModal: { show: true, responseType: action.responseType ? action.responseType : '', message: action.responseMessage } };
        case HIDE_RESPONSE_MODAL:
            return { ...state, responseModal: { show: false, message: null, responseType: '' } };
        case SHOW_BATCH_RENDER_SETTINGS_MODAL:
            return { ...state, renderSettingsModal: { show: true, batch: action.batch } };
        case HIDE_BATCH_RENDER_SETTINGS_MODAL:
            return { ...state, renderSettingsModal: { show: false, batch: undefined } };
        case SHOW_GROUP_RENDER_SETTINGS_MODAL:
            return { ...state, groupRenderSettingsModal: { show: true, group: action.group } };
        case HIDE_GROUP_RENDER_SETTINGS_MODAL:
            return { ...state, groupRenderSettingsModal: { show: false, group: undefined } };

        default:
            throw new Error('Wrong dispatch action type provided in modal reducer!');
    }
};

export default modalReducer;

export const modalReducerInitialState = {
    confirmationModal: { show: false, confirmAction: () => null, confirmMessage: '', groups: undefined },
    responseModal: { show: false, message: null, responseType: '' },
    renderSettingsModal: { show: false, batch: undefined },
    groupRenderSettingsModal: { show: false, group: undefined }
};