import React from 'react';

import './Refresh.css';

const Refresh = (props: { click: () => void }) => {

    return (
        <i
            data-tip='Refresh'
            className="fa fa-refresh RefreshBtn"
            onClick={props.click} />
    );
};

export default Refresh;