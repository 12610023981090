import React, { useReducer, useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import Auxilary from '../components/Auxiliary/Auxiliary';
import Card from '../components/Card/Card';
import Spinner from '../components/UI/Spinner/Spinner';
import useHttp from '../custom-hooks/http/http';
import * as ENDPOINTS from '../endpoints/http-endpoints';
import modalReducer, { modalReducerInitialState } from '../reducers/modal-reducer';
import { ORDER_BATCHES_BY_NAME, ORDER_BATCHES_BY_PRIORITY, ORDER_BATCHES_BY_DATE, REMOVE_BATCHES_FROM_QUEUE, UPDATE_BATCH_RENDER_SETTINGS, ORDER_BATCHES_BY_FRAME, ORDER_BATCHES_BY_JOBS_COUNT, INSERT_QUEUED_BATCHES, UPDATE_GROUP_RENDER_SETTINGS } from '../state/global-state';
import { SHOW_CONFIRMATION_MODAL, HIDE_CONFIRMATION_MODAL, SHOW_BATCH_RENDER_SETTINGS_MODAL, HIDE_BATCH_RENDER_SETTINGS_MODAL, HIDE_RESPONSE_MODAL, SHOW_RESPONSE_MODAL, SHOW_GROUP_RENDER_SETTINGS_MODAL, HIDE_GROUP_RENDER_SETTINGS_MODAL } from '../reducers/modal-reducer-actions';
import QueuedBatchComponent from '../components/QueuedBatch/QueuedBatchComponent';
import ConfirmationModal from '../components/Modals/Confirmation/ConfirmationModal';
import Actions from '../components/Actions/Actions';
import Action from '../components/Actions/Action/Action';
import RenderSettingsModal from '../components/Modals/BatchRenderSettings/RenderSettingsModal';
import { QueuedBatch } from '../models/QueuedBatch';
import { useGlobalState } from '../state/global-state';
import ResponseModal from '../components/Modals/ResponseModal/ResponseModal';
import { ConvertPriorityValue, groupBy, BatchGroup } from '../helpers/priority-helper';
import Logo from '../components/Logo/Logo';
import { sumPropertyFromArray } from '../helpers/sum-jobs-helper';
import ProductGroup from '../components/ProductGroup/ProductGroup';
import GroupRenderSettingsModal from '../components/Modals/GroupRenderSettings/GroupRenderSettings';

const BatchContainer = () => {
    const [{ auth, queuedBatches }, dispatch] = useGlobalState();
    const { request, isLoading } = useHttp();
    const [{ confirmationModal, renderSettingsModal, responseModal, groupRenderSettingsModal }, modalDispatch]
        = useReducer(modalReducer, modalReducerInitialState);
    const [search, setSearch] = useState<string>('');
    const [isFilterLocalRender, setFilterRenderEnvironment] = useState(true);
    const [batches, setBatches] = useState<QueuedBatch[]>([]);
    const [collapsedGroup, setCollapsedGroup] = useState<string>();
    const [currentFilterPriority, setCurrentFilterPriority] = useState<number>(-1);
    const [bulkBatches, setBulkBatches] = useState<QueuedBatch[]>([]);
    const [allSelected, setAllSelected] = useState(false);
    let rowNumberCounter = 0;

    useEffect(() => {
        if (bulkBatches.length === batches.length && batches.length > 0) {
            setAllSelected(true);
        }
        else {
            setAllSelected(false);
        }
    }, [bulkBatches, batches])

    // Add or remove batches in the status
    const manageBulkGroup = (batchIds: QueuedBatch[], isChecked: boolean) => {
        if (isChecked) {
            setBulkBatches([...bulkBatches, ...batchIds]);
        }
        else {
            setBulkBatches([...bulkBatches.filter(f => batchIds.indexOf(f) === -1)]);
        }
    };

    const selectAllGroups = (selected: boolean) => {
        if (selected) {
            setBulkBatches(batches);
        }
        else {
            setBulkBatches([]);
        }
    };


    /* remove batch from queue in db and app state */
    const removeBatchFromQueue = (batchIds: string[], bulk?: boolean) => {
        request(ENDPOINTS.REMOVE_BATCH_FROM_QUEUE, 'POST', auth, batchIds)
            .then(response => {
                if (response) {
                    dispatch({ type: REMOVE_BATCHES_FROM_QUEUE, payload: batchIds });
                    modalDispatch({
                        type: SHOW_RESPONSE_MODAL,
                        responseMessage: <h5> Successfully removed </h5>,
                        responseType: 'success'
                    });

                    if (bulk) {
                        setBulkBatches([]);
                    } else {
                        setBulkBatches([...bulkBatches.filter(b => !batchIds.includes(b.batchId))])
                    }
                }
            })
            .catch(error => {
                modalDispatch({
                    type: SHOW_RESPONSE_MODAL,
                    responseMessage: (
                        <div>
                            <h5> Unsuccessfully removed </h5>
                            <hr />
                            <span> {error.response ? error.response.data : error.message} </span>
                        </div>
                    ),
                    responseType: 'error'
                });

                setBulkBatches([]);
            });
    };

    /* save group batches render settings */
    const saveGroupRenderSettings = (priority: number, cloudRendering: boolean, forTaskRunner: boolean, forChaosCloud: boolean, group?: BatchGroup) => {
        if (group) {
            if (group.batches.every(b => b.priority === priority && b.isCloudRendering === cloudRendering && b.forTaskRunner === forTaskRunner && b.forChaosCloud === forChaosCloud)) {
                modalDispatch({
                    type: SHOW_RESPONSE_MODAL,
                    responseMessage: <h4> No changes detected </h4>,
                    responseType: 'info'
                });
                return false;
            }

            request(ENDPOINTS.UPDATE_BULK_BATCHES_RENDER_SETTINGS, 'POST', auth,
                {
                    batchIds: group.batches.map(b => b.batchId),
                    priority: ConvertPriorityValue(priority),
                    isCloudRendering: cloudRendering,
                    forTaskRunner: forTaskRunner,
                    forChaosCloud: forChaosCloud
                })
                .then(response => {
                    if (response) {
                        // Reload and update state for queued batches
                        request(ENDPOINTS.RETRIEVE_QUEUED_BATCHES, 'GET', { username: auth.username, password: auth.password })
                            .then(response => {
                                if (response) {
                                    dispatch({ type: INSERT_QUEUED_BATCHES, payload: response });
                                }
                            });
                        dispatch({ type: UPDATE_GROUP_RENDER_SETTINGS, payload: { batches: group.batches, priority: priority, cloudRendering: cloudRendering, forTaskRunner: forTaskRunner, forChaosCloud: forChaosCloud } });
                        modalDispatch({
                            type: SHOW_RESPONSE_MODAL,
                            responseMessage: <h5> Successfully updated </h5>,
                            responseType: 'success'
                        })
                    }
                })
                .catch(error =>
                    modalDispatch({
                        type: SHOW_RESPONSE_MODAL,
                        responseMessage: (
                            <div>
                                <h5> Unsuccessfully updated </h5>
                                <hr />
                                <span> {error.response ? error.response.data : error.message} </span>
                            </div>
                        ),
                        responseType: 'error'
                    }));

            if (bulkBatches.length > 0 && group.groupName.indexOf("Bulk group edit") !== -1) {
                setBulkBatches([]);
            }

            return true;
        }
        return false;
    }

    /* on save rendering settings handler */
    const saveRenderSettings = (batch: QueuedBatch, priority: number, cloudRendering: boolean, forTaskRunner: boolean, forChaosCloud: boolean): boolean => {
        if (batch.priority === priority && batch.isCloudRendering === cloudRendering && batch.forTaskRunner === forTaskRunner && batch.forChaosCloud === forChaosCloud) {
            modalDispatch({
                type: SHOW_RESPONSE_MODAL,
                responseMessage: <h5> No changes detected </h5>,
                responseType: 'info'
            });
            return false;
        }

        request(ENDPOINTS.UPDATE_BATCH_RENDER_SETTINGS, 'POST', auth,
            {
                batchId: batch.batchId,
                priority: ConvertPriorityValue(priority),
                isCloudRendering: cloudRendering,
                forTaskRunner: forTaskRunner,
                forChaosCloud: forChaosCloud
            })
            .then(response => {
                if (response) {
                    dispatch({ type: UPDATE_BATCH_RENDER_SETTINGS, payload: { batchId: batch.batchId, priority: priority, cloudRendering: cloudRendering, forTaskRunner: forTaskRunner, forChaosCloud: forChaosCloud } });
                    modalDispatch({
                        type: SHOW_RESPONSE_MODAL,
                        responseMessage: <h5> Successfully updated </h5>,
                        responseType: 'success'
                    })
                }
            })
            .catch(error =>
                modalDispatch({
                    type: SHOW_RESPONSE_MODAL,
                    responseMessage: (
                        <div>
                            <h5> Unsuccessfully updated </h5>
                            <hr />
                            <span> {error.response ? error.response.data : error.message} </span>
                        </div>
                    ),
                    responseType: 'error'
                }));

        return true;
    };

    /* queued batch actions */
    const actions = (batch: QueuedBatch) => (
        <Actions
            title='Batch actions'>
            <Action
                title='Rendering settings'
                disable={false}
                clicked={() => modalDispatch({ type: SHOW_BATCH_RENDER_SETTINGS_MODAL, batch: batch })} />
        </Actions>
    );

    /* grouped of batches actions */
    const groupActions = (group: BatchGroup) => (
        <Actions
            title='Product version actions'>
            <Action
                title='Rendering settings'
                disable={false}
                clicked={() => modalDispatch({ type: SHOW_GROUP_RENDER_SETTINGS_MODAL, group: group })} />
        </Actions>
    );

    /* queued batch actions */
    function getRowNumber(): any {
        rowNumberCounter++;
        return rowNumberCounter;
    }

    /* on inserted data and on any filter applied */
    useEffect(() => {
        if (queuedBatches) {
            setBatches(queuedBatches
                .filter(qrb => isFilterLocalRender
                    ? qrb.isCloudRendering === false || qrb.isCloudRendering === null
                    : qrb.isCloudRendering === true)
                .filter(q => currentFilterPriority === -1
                    ? q.priority !== currentFilterPriority
                    : q.priority === currentFilterPriority)
                .filter(qb => search
                    ? qb.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                    : true))
        }

        if (bulkBatches && bulkBatches.length > 0) {
            setBulkBatches([]);
        }
    }, [queuedBatches, isFilterLocalRender, currentFilterPriority, search])

    return (
        <Auxilary>
            <Logo />
            <Card
                title='Queued batches'
                actions={null}
                search={(value: string) => setSearch(value)}
                refresh={() => request(ENDPOINTS.RETRIEVE_QUEUED_BATCHES, 'GET', { username: auth.username, password: auth.password })
                    .then(response => {
                        if (response) {
                            dispatch({ type: INSERT_QUEUED_BATCHES, payload: response });
                            modalDispatch({ type: SHOW_RESPONSE_MODAL, responseType: 'success', responseMessage: <h5> Up to date </h5> })
                        }
                    })
                    .catch(error => {
                        modalDispatch({
                            type: SHOW_RESPONSE_MODAL, responseMessage: (
                                <div>
                                    <h5> Failed to sync </h5>
                                    <hr />
                                    <span> {error.response ? error.response.data : error.message} </span>
                                </div>
                            ), responseType: 'error'
                        })
                    })}
                filters={{
                    byName: () => dispatch({ type: ORDER_BATCHES_BY_NAME }),
                    byDate: () => dispatch({ type: ORDER_BATCHES_BY_DATE }),
                    byPriority: () => dispatch({ type: ORDER_BATCHES_BY_PRIORITY }),
                    byFrame: () => dispatch({ type: ORDER_BATCHES_BY_FRAME }),
                    byJobsCount: () => dispatch({ type: ORDER_BATCHES_BY_JOBS_COUNT })
                }}
                isFilterLocalRender={isFilterLocalRender}
                setFilterRenderEnvironment={setFilterRenderEnvironment}
                queueCount={{
                    batchCount: batches.length,
                    renderingCount: sumPropertyFromArray(batches, 'renderJobsCount'),
                    denoiseCount: sumPropertyFromArray(batches, 'denoiseJobsCount'),
                    upscaleCount: sumPropertyFromArray(batches, 'upscaleJobsCount'),
                    postCount: sumPropertyFromArray(batches, 'postJobsCount'),
                    contentCount: sumPropertyFromArray(batches, 'contentJobsCount')
                }}
                filterBatchesByPriority={setCurrentFilterPriority}
                currentFilterPriority={currentFilterPriority}
                onBulkGroupEdit={() => {
                    var group = new BatchGroup();
                    group.groupName = 'Bulk group edit - total selected batches: ' + bulkBatches.length.toString();
                    group.batches = bulkBatches;
                    modalDispatch({ type: SHOW_GROUP_RENDER_SETTINGS_MODAL, group: group })
                }}
                showBulkEditModal={bulkBatches.length > 0 ? true : false}
                selectAllGroups={selectAllGroups}
                allSelected={allSelected}
                onBulkRemove={() => modalDispatch({
                    type: SHOW_CONFIRMATION_MODAL,
                    confirmMessage: `Are you sure you want to remove selected batches from queue?`,
                    groups: groupBy(bulkBatches),
                    confirmAction: () => removeBatchFromQueue(bulkBatches.map(b => b.batchId), true)
                })}>
                {
                    groupBy(batches)
                        .map((group, index) => (
                            <ProductGroup
                                actions={groupActions(group)}
                                group={group}
                                key={index}
                                collapse={collapsedGroup === group.groupName}
                                onCollapse={() => collapsedGroup === group.groupName
                                    ? setCollapsedGroup(undefined)
                                    : setCollapsedGroup(group.groupName)}
                                bulkGroupHanlder={manageBulkGroup}
                                isBulkGroupEmpty={bulkBatches.length === 0}
                                isGroupBeenSelected={bulkBatches.some(b => group.batches.some(gb => gb.batchId === b.batchId))}>
                                {group.batches.map((b, index) => {
                                    return (
                                        <QueuedBatchComponent
                                            key={`${b.batchId}_${index}`}
                                            keyNumber={getRowNumber()}
                                            batch={b}
                                            actions={actions(b)}
                                            searchValue={search}
                                            removeBatch={() => modalDispatch({
                                                type: SHOW_CONFIRMATION_MODAL,
                                                confirmMessage: `Are you sure you want to remove selected batch from queue?`,
                                                groups: groupBy([b]),
                                                confirmAction: () => removeBatchFromQueue([b.batchId])
                                            })} />
                                    )
                                })
                                }

                            </ProductGroup>)
                        )
                }
            </Card>

            {/* MODALS */}
            <ConfirmationModal
                title={confirmationModal.confirmMessage}
                groups={confirmationModal.groups}
                onConfirm={confirmationModal.confirmAction}
                showModal={confirmationModal.show}
                toggleModalHandler={() => { modalDispatch({ type: HIDE_CONFIRMATION_MODAL }); }} />
            <RenderSettingsModal
                show={renderSettingsModal.show}
                batch={renderSettingsModal.batch}
                onSave={saveRenderSettings}
                onClose={() => modalDispatch({ type: HIDE_BATCH_RENDER_SETTINGS_MODAL })} />
            <GroupRenderSettingsModal
                show={groupRenderSettingsModal.show}
                groupSettings={
                    groupRenderSettingsModal.group
                        ?
                        {
                            name: groupRenderSettingsModal.group.groupName,
                            isCloudRendering: groupRenderSettingsModal.group.batches[0].isCloudRendering,
                            priority: groupRenderSettingsModal.group.batches[0].priority,
                            forTaskRunner: groupRenderSettingsModal.group.batches[0].forTaskRunner,
                            forChaosCloud: groupRenderSettingsModal.group.batches[0].forChaosCloud
                        }
                        : undefined
                }
                onClose={() => modalDispatch({ type: HIDE_GROUP_RENDER_SETTINGS_MODAL })}
                onGroupSettingsSave={(priority: number, cloudRendering: boolean, forTaskRunner: boolean, forChaosCloud: boolean) => saveGroupRenderSettings(priority, cloudRendering, forTaskRunner, forChaosCloud, groupRenderSettingsModal.group)} />
            <ResponseModal
                show={responseModal.show}
                close={() => modalDispatch({ type: HIDE_RESPONSE_MODAL })}
                type={responseModal.responseType}> {responseModal.message}</ResponseModal>
            <Spinner show={isLoading} />

            {/* TOOLTIP */}
            <ReactTooltip
                delayShow={400}
                place={'top'}
                multiline={true}
                effect={'solid'} />
        </Auxilary >
    );
};

export default BatchContainer;