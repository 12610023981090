import { Priority } from "../models/Priority.enum";
import { QueuedBatch } from "../models/QueuedBatch";
import { useCallback } from "react";
import React from 'react';

export class EnumKeyValue {
    key!: string;
    value!: string;
}

export const RetrievePriorityEnums = (): EnumKeyValue[] => {
    const keys = Object.keys(Priority).filter(k => typeof Priority[k as any] === "number"); // ["A", "B"]
    return keys.map(k => {
        let enumPriority = new EnumKeyValue();
        enumPriority.key = Priority[k as any];
        enumPriority.value = k;
        return enumPriority;
    });

};
/* create option for provided enum value */
export const CreateOption = (option: EnumKeyValue): JSX.Element | void => {
    return (
        <option
            key={option.key}
            value={option.key}>
            {`${option.key} (${option.value})`}  {/* 2 (low) */}
        </option >
    );
};

export const ConvertPriorityValue = (value: number): number => {
    if(value === 1){
        return 1;
    }
    if(value === 2){
        return 20
    }
    if(value === 3){
        return 40;
    }
    if(value === 4){
        return 60;
    }
    if(value === 5){
        return 80;
    }

    if(value <= 19){
        return 1;
    }
    if(value <= 39){
        return 2;
    }
    if(value <= 59){
        return 3;
    }
    if(value <= 79){
        return 4;
    }
    return 5;
    //  switch (value) {
    //     /* in react app */
    //     case 1:
    //         return 1;
    //     case 25:
    //         return 2;
    //     case 50:
    //         return 3;
    //     case 75:
    //         return 4;
    //     case 100:
    //         return 5;

    //     /* in render pipeline */
    //     case 2:
    //         return 25;
    //     case 3:
    //         return 50;
    //     case 4:
    //         return 75;
    //     case 5:
    //         return 100;


    //     default:
    //         return 0;
    // }
};


export const groupBy = (array: QueuedBatch[]): BatchGroup[] => {
    var groups = array.reduce((objectsByKeyValue: any, obj: QueuedBatch) => {
        var endIndex = obj.name.indexOf(', Batch');
        if (endIndex !== -1) {
            endIndex = endIndex;
            const group = obj.name.substring(0, endIndex);
            objectsByKeyValue[group] = (objectsByKeyValue[group] || []).concat(obj);
            return objectsByKeyValue;
        }
    }, {});

    var batchGroups = asBatchGroups(groups);

    return batchGroups;
}

const asBatchGroups = (groups: any): BatchGroup[] => {
    var batchGroups: BatchGroup[] = [];
    var keys = Object.keys(groups);
    for (var i = 0; i < keys.length; i++) {
        batchGroups.push({ groupName: keys[i], batches: groups[keys[i]] });
    }
    return batchGroups;
};

export class BatchGroup {
    groupName!: string;
    batches!: QueuedBatch[];
}