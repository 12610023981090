import React from 'react';

import { sumJobs } from '../../../helpers/sum-jobs-helper';
import Auxilary from '../../Auxiliary/Auxiliary';

const CardFooter = (props: { batchCount: number, renderingCount: number, denoiseCount: number, upscaleCount: number, postCount: number, contentCount: number }) => {
    return (
        <Auxilary>
            <div className="card-footer" style={{ textAlign: 'center', padding: '0.35vh' }}>
                <div className="row">
                    <div className="col-md-2">
                        <span className='text-dark'>Queued batches count:&nbsp; <span data-tip='Total queued batches count'>{props.batchCount}</span>
                        </span>
                    </div>
                    <div className="offset-md-7 col-md-3">
                        <span className='text-dark'>
                            Queued jobs count: &nbsp;
                                <span data-tip='Total rendering queued jobs'>{props.renderingCount}</span>/
                                <span data-tip='Total denoise queued jobs'>{props.denoiseCount}</span>/
                                <span data-tip='Total upscale queued jobs'>{props.upscaleCount}</span>/
                                <span data-tip='Total post queued jobs'>{props.postCount}</span>/
                                <span data-tip='Total content queued jobs'>{props.contentCount}</span> -&nbsp;
                                <span data-tip='Total queued jobs'>{sumJobs(props.renderingCount, props.denoiseCount, props.upscaleCount, props.postCount, props.contentCount)}</span>
                        </span>
                    </div>
                </div>
            </div>

        </Auxilary>
    );
};

export default CardFooter;