import React from 'react';

import './Logo.css';

const Logo = () => {

    return (
        <svg
            className='Logo'
            viewBox="0 0 46 40"
            width='90'
            height='90'
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.8 6.60054L34.797 1.21561C34.797 0.961452 34.7607 0.828327 34.6521 0.699986C32.967 0.699986 20.2587 7.33046 20.2002 19.9562C20.2587 32.6695 32.967 39.3 34.6521 39.3C34.7607 39.1716 34.797 39.0385 34.797 38.7844L34.8 33.4012C31.5852 33.4012 24.7714 28.2143 24.7352 20C24.7714 11.7859 31.5852 6.60054 34.8 6.60054Z"
                fill="url(#paint0_linear)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.1275 34.16C22.2573 34.16 15.8772 27.8204 15.8772 20C15.8772 12.1796 22.2573 5.84 30.1275 5.84C31.7564 5.84 33.3413 6.10017 34.7994 6.60036L34.797 1.05747C34.797 0.769871 34.5995 0.49894 34.3272 0.436016C32.9726 0.150314 31.5677 0 30.1275 0C19.0114 0 10 8.95431 10 20C10 31.0457 19.0114 40 30.1275 40C31.5689 40 32.975 39.8494 34.3306 39.5633C34.6091 39.4988 34.797 39.2373 34.797 38.9429L34.7994 33.4009C33.3396 33.9025 31.7588 34.16 30.1275 34.16Z"
                fill="#E2FFFC" />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="20.2"
                    y1="-13.6048"
                    x2="32.6"
                    y2="-13.6048"
                    gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor="white"
                        stopOpacity="0.28" />
                    <stop
                        offset="1"
                        stopColor="white" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default Logo;