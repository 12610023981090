import React from 'react';

interface ActionProps { title: string; clicked: any; disable: boolean; }

const Action = (props: ActionProps) => {

    return (
        <a
            className={'dropdown-item'}
            href="#"
            onClick={props.clicked}>{props.title}</a>);
};

export default Action;