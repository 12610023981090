import React, { useState, useEffect } from 'react';
import classes from '../../styles/styles.module.css';
import { BatchGroup } from '../../helpers/priority-helper';
import { sumJobs, sumGroupJobsCount } from '../../helpers/sum-jobs-helper';
import { JobType } from '../../models/Job-type.enum';
import { QueuedBatch } from '../../models/QueuedBatch';

const ProductGroup = (props: {
    group: BatchGroup,
    collapse: boolean,
    onCollapse: () => void,
    actions: JSX.Element,
    children: any,
    bulkGroupHanlder: (batchIds: QueuedBatch[], isChecked: boolean) => any,
    isBulkGroupEmpty: boolean,
    isGroupBeenSelected: boolean
}) => {

    const [checkbox, setCheckbox] = useState(false);
    const [groupHovered, setGroupHovered] = useState(false);

    useEffect(() => {

        if (props.isBulkGroupEmpty === true && checkbox === true) {
            setCheckbox(false);
        }

        /* prop was needed because when we apply filter on batches we might lose this group component so when we remove the filter and re-render this comp it will lose the previous state (selected or not) */
        if (props.isGroupBeenSelected) {
            setCheckbox(true);
        }

        /* on component destroy set the checkbox state as false */
        return () => setCheckbox(false);

    }, [props.isBulkGroupEmpty, props.isGroupBeenSelected])


    return (
        <div onMouseOver={() => setGroupHovered(true)} onMouseLeave={() => setGroupHovered(false)}>
            <div className={['card-header py-1.5', classes.GroupBody].join(' ')}>
                <div className="row" style={{ textAlign: 'center' }}>
                    <div className="col-md-5" style={{ textAlign: 'left' }}>
                        <div className="row" style={{ textAlign: 'left' }}>
                            <div className="col-md-1" style={{ textAlign: 'left', left: '0.9vh', top: '0.15vh' }}>
                                {
                                    groupHovered  || props.isGroupBeenSelected ?
                                        <input
                                            style={{ cursor: 'pointer' }}
                                            name="bulkUpdate"
                                            type="checkbox"
                                            checked={checkbox}
                                            title="Include this group in the bulk update operation"
                                            onChange={(e) => {
                                                setCheckbox(e.currentTarget.checked);
                                                props.bulkGroupHanlder(props.group.batches, e.currentTarget.checked);
                                            }} /> : null
                                }
                            </div>
                            <div className="col-md-11" style={{ textAlign: 'left' }}>
                                {props.group.groupName} &nbsp;{`(${props.group.batches.length} ${props.group.batches.length > 1 ? 'Batches' : 'Batch'})`}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1">
                        {props.group.batches[0].frameCount}
                    </div>
                    <div className="col-md-2">
                        {`${sumGroupJobsCount(props.group, JobType.rendering)}/${sumGroupJobsCount(props.group, JobType.denoise)}/${sumGroupJobsCount(props.group, JobType.upscale)}/${sumGroupJobsCount(props.group, JobType.post)}/${sumGroupJobsCount(props.group, JobType.content)} - ${sumJobs(sumGroupJobsCount(props.group, JobType.rendering), sumJobs(sumGroupJobsCount(props.group, JobType.denoise), sumJobs(sumGroupJobsCount(props.group, JobType.upscale), sumGroupJobsCount(props.group, JobType.post), sumGroupJobsCount(props.group, JobType.content))))}`}
                    </div>
                    <div className="col-md-2">
                        {props.group.batches[0].dateCreated.toUTCString()}
                    </div>
                    <div className="col-md-1">
                        {props.group.batches[0].priority}
                    </div>
                    <div className="col-md-1">
                        <div className="row">
                            {/* <div className="col-md-6" style={{ textAlign: 'right' }} title='Group batch count'>
                                {props.group.batches.length}
                            </div> */}
                            <div className="offset-md-4 col-md-4" style={{ paddingTop: '0.3vh', paddingRight: '2.3vh' }}>
                                {props.actions}
                            </div>
                            <div className="col-md-1">
                                <i
                                    style={{ cursor: 'pointer' }}
                                    className={props.collapse ? 'fa fa-angle-up' : 'fa fa-angle-down'}
                                    onClick={props.onCollapse} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Overflow'
                style={{
                    maxHeight: '50vh',
                    overflowY: props.children.length > 10 ? 'auto' : 'unset',
                    overflowX: props.children.length > 10 ? 'hidden' : 'unset'
                }}>
                {props.collapse ? props.children : null}
            </div>
        </div>
    );
};

export default ProductGroup;