import React, { useState, useRef, useEffect } from 'react';
import './Search.css';

const Search = (props: { search: (value: string) => void }) => {
    const [search, setSearch] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (search) {
            inputRef.current && inputRef.current.focus();
        }
        else {
            props.search('');
        }
    }, [search])

    return (
        <div>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span
                        data-tip='Search per name'
                        className="input-group-text"
                        style={{ backgroundColor: 'transparent', cursor: 'pointer' }}
                        onClick={() => setSearch(!search)}>
                        <i className='fa fa-search' />
                    </span>
                </div>
                {search
                    ?
                    <input
                        ref={inputRef}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.search(e.currentTarget.value)}
                        className="form-control SearchInput"
                        type="text"
                        placeholder="Search"
                        aria-label="Search" />
                    : null}
            </div>
        </div>);
};

export default Search;