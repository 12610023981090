import React, { useState, useEffect, useCallback } from 'react';
import { Checkbox } from 'pretty-checkbox-react';

import Modal from '../../UI/Modal/Modal';
import { RetrievePriorityEnums, EnumKeyValue, CreateOption } from '../../../helpers/priority-helper';
import backdropClasses from '../../UI/Backdrop/Backdrop.module.css';
import modalClasses from '../../UI/Modal/Modal.module.css';

const GroupRenderSettingsModal = (props: {
    groupSettings?: { name: string, isCloudRendering?: boolean, priority: number, forTaskRunner?: boolean, forChaosCloud?: boolean },
    show: boolean,
    onClose: () => void,
    onGroupSettingsSave: (priority: number, cloudRendering: boolean, forTaskRunner: boolean, forChaosCloud: boolean) => boolean
}) => {
    const [checked, setChecked] = useState(false);
    const [priority, setPriority] = useState<number | undefined>();
    const [enums, setEnums] = useState<EnumKeyValue[]>();
    const [forTaskRunner, setForTaskRunner] = useState(false);
    const [forChaosCloud, setForChaosCloud] = useState(false);

    /* on group settings changed fill data */
    useEffect(() => {
        if (props.groupSettings) {
            setChecked(props.groupSettings.isCloudRendering ? true : false);
            setPriority(props.groupSettings.priority ? props.groupSettings.priority : undefined);
            setForTaskRunner(props.groupSettings.forTaskRunner ? true : false);
            setForChaosCloud(props.groupSettings.forChaosCloud ? true : false);
        }
    }, [props.groupSettings])

    /* on component mount */
    useEffect(() => {
        setEnums(RetrievePriorityEnums());
        return () => { setChecked(false); };

    }, [])

    return (
        <Modal
            show={props.show}
            closeForm={props.onClose}
            backdropClasses={backdropClasses.LowIndexBackdrop}
            classes={modalClasses.LowIndexModal}>
            <div style={{ textAlign: 'center' }}>
                <h5 className='text-info'>{props.groupSettings && props.groupSettings.name}</h5>
                <hr />
                <h3 className='text-info'> Rendering settings </h3>
                <br />
                <br />
                <div className="row">
                    <div className="offset-md-3 col-md-3">
                        <div className="row">
                            <div className="col-md-3">
                                <label>Priority</label>
                            </div>
                            <div className="col-md-8" style={{ bottom: '7px' }}>
                                <select
                                    className='custom-select'
                                    value={priority}
                                    onChange={(e) => setPriority(+e.currentTarget.value)}>
                                    {
                                        enums && enums.map((option: EnumKeyValue) => CreateOption(option))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-4">
                                <label>Cloud rendering:</label>
                            </div>
                            <div className="col-md-1">
                                <Checkbox
                                    state={checked}
                                    checked={checked}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChecked(e.currentTarget.checked)}
                                    shape='round'
                                    color='success'
                                    animation='smooth' />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-4">
                                <label>For TaskRunner:</label>
                            </div>
                            <div className="col-md-1">
                                <Checkbox
                                    state={forTaskRunner}
                                    checked={forTaskRunner}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setForTaskRunner(e.currentTarget.checked)}
                                    shape='round'
                                    color='success'
                                    animation='smooth' />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-4">
                                <label>For Chaos Cloud:</label>
                            </div>
                            <div className="col-md-1">
                                <Checkbox
                                    state={forChaosCloud}
                                    checked={forChaosCloud}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setForChaosCloud(e.currentTarget.checked)}
                                    shape='round'
                                    color='success'
                                    animation='smooth' />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <hr />
                <button
                    className='btn'
                    style={{ backgroundColor: 'rgb(20, 176, 156)', color: 'white' }}
                    onClick={() => {
                        if (priority) {
                            if (props.onGroupSettingsSave(priority, checked, forTaskRunner, forChaosCloud))
                                props.onClose();
                        }
                    }}> Update </button>
                <button
                    className='btn btn-danger'
                    onClick={props.onClose}> Cancel </button>
            </div>
        </Modal>
    );
};

export default GroupRenderSettingsModal;