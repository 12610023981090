import React from 'react';

interface Props { title: string; children: JSX.Element | JSX.Element[]; }

const Actions = (props: Props) => {
    return (
        <div
            className="dropdown no-arrow"
            data-tip={props.title}>
            <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-700"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                <div className="dropdown-header text-info">{props.title}</div>
                {props.children}
            </div>
        </div>);
};

export default Actions;